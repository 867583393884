import { loadRestJsonErrorCode, parseJsonBody as parseBody, parseJsonErrorBody as parseErrorBody } from "@aws-sdk/core";
import { requestBuilder as rb } from "@smithy/core";
import { isValidHostname as __isValidHostname, } from "@smithy/protocol-http";
import { _json, collectBody, decorateServiceException as __decorateServiceException, expectBoolean as __expectBoolean, expectInt32 as __expectInt32, expectNonNull as __expectNonNull, expectObject as __expectObject, expectString as __expectString, limitedParseDouble as __limitedParseDouble, map, parseRfc3339DateTimeWithOffset as __parseRfc3339DateTimeWithOffset, serializeDateTime as __serializeDateTime, serializeFloat as __serializeFloat, take, withBaseException, } from "@smithy/smithy-client";
import { LocationServiceException as __BaseException } from "../models/LocationServiceException";
import { AccessDeniedException, ConflictException, InternalServerException, ResourceNotFoundException, ServiceQuotaExceededException, ThrottlingException, ValidationException, } from "../models/models_0";
export const se_AssociateTrackerConsumerCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/tracking/v0/trackers/{TrackerName}/consumers");
    b.p("TrackerName", () => input.TrackerName, "{TrackerName}", false);
    let body;
    body = JSON.stringify(take(input, {
        ConsumerArn: [],
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "cp.tracking." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("POST").h(headers).b(body);
    return b.build();
};
export const se_BatchDeleteDevicePositionHistoryCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/tracking/v0/trackers/{TrackerName}/delete-positions");
    b.p("TrackerName", () => input.TrackerName, "{TrackerName}", false);
    let body;
    body = JSON.stringify(take(input, {
        DeviceIds: (_) => _json(_),
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "tracking." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("POST").h(headers).b(body);
    return b.build();
};
export const se_BatchDeleteGeofenceCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/geofencing/v0/collections/{CollectionName}/delete-geofences");
    b.p("CollectionName", () => input.CollectionName, "{CollectionName}", false);
    let body;
    body = JSON.stringify(take(input, {
        GeofenceIds: (_) => _json(_),
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "geofencing." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("POST").h(headers).b(body);
    return b.build();
};
export const se_BatchEvaluateGeofencesCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/geofencing/v0/collections/{CollectionName}/positions");
    b.p("CollectionName", () => input.CollectionName, "{CollectionName}", false);
    let body;
    body = JSON.stringify(take(input, {
        DevicePositionUpdates: (_) => se_DevicePositionUpdateList(_, context),
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "geofencing." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("POST").h(headers).b(body);
    return b.build();
};
export const se_BatchGetDevicePositionCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/tracking/v0/trackers/{TrackerName}/get-positions");
    b.p("TrackerName", () => input.TrackerName, "{TrackerName}", false);
    let body;
    body = JSON.stringify(take(input, {
        DeviceIds: (_) => _json(_),
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "tracking." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("POST").h(headers).b(body);
    return b.build();
};
export const se_BatchPutGeofenceCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/geofencing/v0/collections/{CollectionName}/put-geofences");
    b.p("CollectionName", () => input.CollectionName, "{CollectionName}", false);
    let body;
    body = JSON.stringify(take(input, {
        Entries: (_) => se_BatchPutGeofenceRequestEntryList(_, context),
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "geofencing." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("POST").h(headers).b(body);
    return b.build();
};
export const se_BatchUpdateDevicePositionCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/tracking/v0/trackers/{TrackerName}/positions");
    b.p("TrackerName", () => input.TrackerName, "{TrackerName}", false);
    let body;
    body = JSON.stringify(take(input, {
        Updates: (_) => se_DevicePositionUpdateList(_, context),
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "tracking." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("POST").h(headers).b(body);
    return b.build();
};
export const se_CalculateRouteCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/routes/v0/calculators/{CalculatorName}/calculate/route");
    b.p("CalculatorName", () => input.CalculatorName, "{CalculatorName}", false);
    const query = map({
        [_k]: [, input[_K]],
    });
    let body;
    body = JSON.stringify(take(input, {
        ArrivalTime: (_) => __serializeDateTime(_),
        CarModeOptions: (_) => _json(_),
        DepartNow: [],
        DeparturePosition: (_) => se_Position(_, context),
        DepartureTime: (_) => __serializeDateTime(_),
        DestinationPosition: (_) => se_Position(_, context),
        DistanceUnit: [],
        IncludeLegGeometry: [],
        OptimizeFor: [],
        TravelMode: [],
        TruckModeOptions: (_) => se_CalculateRouteTruckModeOptions(_, context),
        WaypointPositions: (_) => se_WaypointPositionList(_, context),
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "routes." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("POST").h(headers).q(query).b(body);
    return b.build();
};
export const se_CalculateRouteMatrixCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/routes/v0/calculators/{CalculatorName}/calculate/route-matrix");
    b.p("CalculatorName", () => input.CalculatorName, "{CalculatorName}", false);
    const query = map({
        [_k]: [, input[_K]],
    });
    let body;
    body = JSON.stringify(take(input, {
        CarModeOptions: (_) => _json(_),
        DepartNow: [],
        DeparturePositions: (_) => se_PositionList(_, context),
        DepartureTime: (_) => __serializeDateTime(_),
        DestinationPositions: (_) => se_PositionList(_, context),
        DistanceUnit: [],
        TravelMode: [],
        TruckModeOptions: (_) => se_CalculateRouteTruckModeOptions(_, context),
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "routes." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("POST").h(headers).q(query).b(body);
    return b.build();
};
export const se_CreateGeofenceCollectionCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/geofencing/v0/collections");
    let body;
    body = JSON.stringify(take(input, {
        CollectionName: [],
        Description: [],
        KmsKeyId: [],
        PricingPlan: [],
        PricingPlanDataSource: [],
        Tags: (_) => _json(_),
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "cp.geofencing." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("POST").h(headers).b(body);
    return b.build();
};
export const se_CreateKeyCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/metadata/v0/keys");
    let body;
    body = JSON.stringify(take(input, {
        Description: [],
        ExpireTime: (_) => __serializeDateTime(_),
        KeyName: [],
        NoExpiry: [],
        Restrictions: (_) => _json(_),
        Tags: (_) => _json(_),
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "cp.metadata." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("POST").h(headers).b(body);
    return b.build();
};
export const se_CreateMapCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/maps/v0/maps");
    let body;
    body = JSON.stringify(take(input, {
        Configuration: (_) => _json(_),
        Description: [],
        MapName: [],
        PricingPlan: [],
        Tags: (_) => _json(_),
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "cp.maps." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("POST").h(headers).b(body);
    return b.build();
};
export const se_CreatePlaceIndexCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/places/v0/indexes");
    let body;
    body = JSON.stringify(take(input, {
        DataSource: [],
        DataSourceConfiguration: (_) => _json(_),
        Description: [],
        IndexName: [],
        PricingPlan: [],
        Tags: (_) => _json(_),
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "cp.places." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("POST").h(headers).b(body);
    return b.build();
};
export const se_CreateRouteCalculatorCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/routes/v0/calculators");
    let body;
    body = JSON.stringify(take(input, {
        CalculatorName: [],
        DataSource: [],
        Description: [],
        PricingPlan: [],
        Tags: (_) => _json(_),
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "cp.routes." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("POST").h(headers).b(body);
    return b.build();
};
export const se_CreateTrackerCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/tracking/v0/trackers");
    let body;
    body = JSON.stringify(take(input, {
        Description: [],
        EventBridgeEnabled: [],
        KmsKeyEnableGeospatialQueries: [],
        KmsKeyId: [],
        PositionFiltering: [],
        PricingPlan: [],
        PricingPlanDataSource: [],
        Tags: (_) => _json(_),
        TrackerName: [],
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "cp.tracking." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("POST").h(headers).b(body);
    return b.build();
};
export const se_DeleteGeofenceCollectionCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/geofencing/v0/collections/{CollectionName}");
    b.p("CollectionName", () => input.CollectionName, "{CollectionName}", false);
    let body;
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "cp.geofencing." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("DELETE").h(headers).b(body);
    return b.build();
};
export const se_DeleteKeyCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/metadata/v0/keys/{KeyName}");
    b.p("KeyName", () => input.KeyName, "{KeyName}", false);
    const query = map({
        [_fD]: [() => input.ForceDelete !== void 0, () => input[_FD].toString()],
    });
    let body;
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "cp.metadata." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("DELETE").h(headers).q(query).b(body);
    return b.build();
};
export const se_DeleteMapCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/maps/v0/maps/{MapName}");
    b.p("MapName", () => input.MapName, "{MapName}", false);
    let body;
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "cp.maps." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("DELETE").h(headers).b(body);
    return b.build();
};
export const se_DeletePlaceIndexCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/places/v0/indexes/{IndexName}");
    b.p("IndexName", () => input.IndexName, "{IndexName}", false);
    let body;
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "cp.places." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("DELETE").h(headers).b(body);
    return b.build();
};
export const se_DeleteRouteCalculatorCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/routes/v0/calculators/{CalculatorName}");
    b.p("CalculatorName", () => input.CalculatorName, "{CalculatorName}", false);
    let body;
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "cp.routes." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("DELETE").h(headers).b(body);
    return b.build();
};
export const se_DeleteTrackerCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/tracking/v0/trackers/{TrackerName}");
    b.p("TrackerName", () => input.TrackerName, "{TrackerName}", false);
    let body;
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "cp.tracking." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("DELETE").h(headers).b(body);
    return b.build();
};
export const se_DescribeGeofenceCollectionCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/geofencing/v0/collections/{CollectionName}");
    b.p("CollectionName", () => input.CollectionName, "{CollectionName}", false);
    let body;
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "cp.geofencing." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("GET").h(headers).b(body);
    return b.build();
};
export const se_DescribeKeyCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/metadata/v0/keys/{KeyName}");
    b.p("KeyName", () => input.KeyName, "{KeyName}", false);
    let body;
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "cp.metadata." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("GET").h(headers).b(body);
    return b.build();
};
export const se_DescribeMapCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/maps/v0/maps/{MapName}");
    b.p("MapName", () => input.MapName, "{MapName}", false);
    let body;
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "cp.maps." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("GET").h(headers).b(body);
    return b.build();
};
export const se_DescribePlaceIndexCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/places/v0/indexes/{IndexName}");
    b.p("IndexName", () => input.IndexName, "{IndexName}", false);
    let body;
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "cp.places." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("GET").h(headers).b(body);
    return b.build();
};
export const se_DescribeRouteCalculatorCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/routes/v0/calculators/{CalculatorName}");
    b.p("CalculatorName", () => input.CalculatorName, "{CalculatorName}", false);
    let body;
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "cp.routes." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("GET").h(headers).b(body);
    return b.build();
};
export const se_DescribeTrackerCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/tracking/v0/trackers/{TrackerName}");
    b.p("TrackerName", () => input.TrackerName, "{TrackerName}", false);
    let body;
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "cp.tracking." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("GET").h(headers).b(body);
    return b.build();
};
export const se_DisassociateTrackerConsumerCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/tracking/v0/trackers/{TrackerName}/consumers/{ConsumerArn}");
    b.p("TrackerName", () => input.TrackerName, "{TrackerName}", false);
    b.p("ConsumerArn", () => input.ConsumerArn, "{ConsumerArn}", false);
    let body;
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "cp.tracking." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("DELETE").h(headers).b(body);
    return b.build();
};
export const se_ForecastGeofenceEventsCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/geofencing/v0/collections/{CollectionName}/forecast-geofence-events");
    b.p("CollectionName", () => input.CollectionName, "{CollectionName}", false);
    let body;
    body = JSON.stringify(take(input, {
        DeviceState: (_) => se_ForecastGeofenceEventsDeviceState(_, context),
        DistanceUnit: [],
        MaxResults: [],
        NextToken: [],
        SpeedUnit: [],
        TimeHorizonMinutes: (_) => __serializeFloat(_),
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "geofencing." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("POST").h(headers).b(body);
    return b.build();
};
export const se_GetDevicePositionCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/tracking/v0/trackers/{TrackerName}/devices/{DeviceId}/positions/latest");
    b.p("TrackerName", () => input.TrackerName, "{TrackerName}", false);
    b.p("DeviceId", () => input.DeviceId, "{DeviceId}", false);
    let body;
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "tracking." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("GET").h(headers).b(body);
    return b.build();
};
export const se_GetDevicePositionHistoryCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/tracking/v0/trackers/{TrackerName}/devices/{DeviceId}/list-positions");
    b.p("TrackerName", () => input.TrackerName, "{TrackerName}", false);
    b.p("DeviceId", () => input.DeviceId, "{DeviceId}", false);
    let body;
    body = JSON.stringify(take(input, {
        EndTimeExclusive: (_) => __serializeDateTime(_),
        MaxResults: [],
        NextToken: [],
        StartTimeInclusive: (_) => __serializeDateTime(_),
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "tracking." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("POST").h(headers).b(body);
    return b.build();
};
export const se_GetGeofenceCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/geofencing/v0/collections/{CollectionName}/geofences/{GeofenceId}");
    b.p("CollectionName", () => input.CollectionName, "{CollectionName}", false);
    b.p("GeofenceId", () => input.GeofenceId, "{GeofenceId}", false);
    let body;
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "geofencing." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("GET").h(headers).b(body);
    return b.build();
};
export const se_GetMapGlyphsCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/maps/v0/maps/{MapName}/glyphs/{FontStack}/{FontUnicodeRange}");
    b.p("MapName", () => input.MapName, "{MapName}", false);
    b.p("FontStack", () => input.FontStack, "{FontStack}", false);
    b.p("FontUnicodeRange", () => input.FontUnicodeRange, "{FontUnicodeRange}", false);
    const query = map({
        [_k]: [, input[_K]],
    });
    let body;
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "maps." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("GET").h(headers).q(query).b(body);
    return b.build();
};
export const se_GetMapSpritesCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/maps/v0/maps/{MapName}/sprites/{FileName}");
    b.p("MapName", () => input.MapName, "{MapName}", false);
    b.p("FileName", () => input.FileName, "{FileName}", false);
    const query = map({
        [_k]: [, input[_K]],
    });
    let body;
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "maps." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("GET").h(headers).q(query).b(body);
    return b.build();
};
export const se_GetMapStyleDescriptorCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/maps/v0/maps/{MapName}/style-descriptor");
    b.p("MapName", () => input.MapName, "{MapName}", false);
    const query = map({
        [_k]: [, input[_K]],
    });
    let body;
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "maps." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("GET").h(headers).q(query).b(body);
    return b.build();
};
export const se_GetMapTileCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/maps/v0/maps/{MapName}/tiles/{Z}/{X}/{Y}");
    b.p("MapName", () => input.MapName, "{MapName}", false);
    b.p("Z", () => input.Z, "{Z}", false);
    b.p("X", () => input.X, "{X}", false);
    b.p("Y", () => input.Y, "{Y}", false);
    const query = map({
        [_k]: [, input[_K]],
    });
    let body;
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "maps." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("GET").h(headers).q(query).b(body);
    return b.build();
};
export const se_GetPlaceCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/places/v0/indexes/{IndexName}/places/{PlaceId}");
    b.p("IndexName", () => input.IndexName, "{IndexName}", false);
    b.p("PlaceId", () => input.PlaceId, "{PlaceId}", false);
    const query = map({
        [_l]: [, input[_L]],
        [_k]: [, input[_K]],
    });
    let body;
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "places." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("GET").h(headers).q(query).b(body);
    return b.build();
};
export const se_ListDevicePositionsCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/tracking/v0/trackers/{TrackerName}/list-positions");
    b.p("TrackerName", () => input.TrackerName, "{TrackerName}", false);
    let body;
    body = JSON.stringify(take(input, {
        FilterGeometry: (_) => se_TrackingFilterGeometry(_, context),
        MaxResults: [],
        NextToken: [],
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "tracking." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("POST").h(headers).b(body);
    return b.build();
};
export const se_ListGeofenceCollectionsCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/geofencing/v0/list-collections");
    let body;
    body = JSON.stringify(take(input, {
        MaxResults: [],
        NextToken: [],
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "cp.geofencing." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("POST").h(headers).b(body);
    return b.build();
};
export const se_ListGeofencesCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/geofencing/v0/collections/{CollectionName}/list-geofences");
    b.p("CollectionName", () => input.CollectionName, "{CollectionName}", false);
    let body;
    body = JSON.stringify(take(input, {
        MaxResults: [],
        NextToken: [],
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "geofencing." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("POST").h(headers).b(body);
    return b.build();
};
export const se_ListKeysCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/metadata/v0/list-keys");
    let body;
    body = JSON.stringify(take(input, {
        Filter: (_) => _json(_),
        MaxResults: [],
        NextToken: [],
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "cp.metadata." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("POST").h(headers).b(body);
    return b.build();
};
export const se_ListMapsCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/maps/v0/list-maps");
    let body;
    body = JSON.stringify(take(input, {
        MaxResults: [],
        NextToken: [],
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "cp.maps." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("POST").h(headers).b(body);
    return b.build();
};
export const se_ListPlaceIndexesCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/places/v0/list-indexes");
    let body;
    body = JSON.stringify(take(input, {
        MaxResults: [],
        NextToken: [],
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "cp.places." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("POST").h(headers).b(body);
    return b.build();
};
export const se_ListRouteCalculatorsCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/routes/v0/list-calculators");
    let body;
    body = JSON.stringify(take(input, {
        MaxResults: [],
        NextToken: [],
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "cp.routes." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("POST").h(headers).b(body);
    return b.build();
};
export const se_ListTagsForResourceCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/tags/{ResourceArn}");
    b.p("ResourceArn", () => input.ResourceArn, "{ResourceArn}", false);
    let body;
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "cp.metadata." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("GET").h(headers).b(body);
    return b.build();
};
export const se_ListTrackerConsumersCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/tracking/v0/trackers/{TrackerName}/list-consumers");
    b.p("TrackerName", () => input.TrackerName, "{TrackerName}", false);
    let body;
    body = JSON.stringify(take(input, {
        MaxResults: [],
        NextToken: [],
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "cp.tracking." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("POST").h(headers).b(body);
    return b.build();
};
export const se_ListTrackersCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/tracking/v0/list-trackers");
    let body;
    body = JSON.stringify(take(input, {
        MaxResults: [],
        NextToken: [],
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "cp.tracking." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("POST").h(headers).b(body);
    return b.build();
};
export const se_PutGeofenceCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/geofencing/v0/collections/{CollectionName}/geofences/{GeofenceId}");
    b.p("CollectionName", () => input.CollectionName, "{CollectionName}", false);
    b.p("GeofenceId", () => input.GeofenceId, "{GeofenceId}", false);
    let body;
    body = JSON.stringify(take(input, {
        GeofenceProperties: (_) => _json(_),
        Geometry: (_) => se_GeofenceGeometry(_, context),
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "geofencing." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("PUT").h(headers).b(body);
    return b.build();
};
export const se_SearchPlaceIndexForPositionCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/places/v0/indexes/{IndexName}/search/position");
    b.p("IndexName", () => input.IndexName, "{IndexName}", false);
    const query = map({
        [_k]: [, input[_K]],
    });
    let body;
    body = JSON.stringify(take(input, {
        Language: [],
        MaxResults: [],
        Position: (_) => se_Position(_, context),
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "places." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("POST").h(headers).q(query).b(body);
    return b.build();
};
export const se_SearchPlaceIndexForSuggestionsCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/places/v0/indexes/{IndexName}/search/suggestions");
    b.p("IndexName", () => input.IndexName, "{IndexName}", false);
    const query = map({
        [_k]: [, input[_K]],
    });
    let body;
    body = JSON.stringify(take(input, {
        BiasPosition: (_) => se_Position(_, context),
        FilterBBox: (_) => se_BoundingBox(_, context),
        FilterCategories: (_) => _json(_),
        FilterCountries: (_) => _json(_),
        Language: [],
        MaxResults: [],
        Text: [],
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "places." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("POST").h(headers).q(query).b(body);
    return b.build();
};
export const se_SearchPlaceIndexForTextCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/places/v0/indexes/{IndexName}/search/text");
    b.p("IndexName", () => input.IndexName, "{IndexName}", false);
    const query = map({
        [_k]: [, input[_K]],
    });
    let body;
    body = JSON.stringify(take(input, {
        BiasPosition: (_) => se_Position(_, context),
        FilterBBox: (_) => se_BoundingBox(_, context),
        FilterCategories: (_) => _json(_),
        FilterCountries: (_) => _json(_),
        Language: [],
        MaxResults: [],
        Text: [],
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "places." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("POST").h(headers).q(query).b(body);
    return b.build();
};
export const se_TagResourceCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/tags/{ResourceArn}");
    b.p("ResourceArn", () => input.ResourceArn, "{ResourceArn}", false);
    let body;
    body = JSON.stringify(take(input, {
        Tags: (_) => _json(_),
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "cp.metadata." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("POST").h(headers).b(body);
    return b.build();
};
export const se_UntagResourceCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/tags/{ResourceArn}");
    b.p("ResourceArn", () => input.ResourceArn, "{ResourceArn}", false);
    const query = map({
        [_tK]: [__expectNonNull(input.TagKeys, `TagKeys`) != null, () => input[_TK] || []],
    });
    let body;
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "cp.metadata." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("DELETE").h(headers).q(query).b(body);
    return b.build();
};
export const se_UpdateGeofenceCollectionCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/geofencing/v0/collections/{CollectionName}");
    b.p("CollectionName", () => input.CollectionName, "{CollectionName}", false);
    let body;
    body = JSON.stringify(take(input, {
        Description: [],
        PricingPlan: [],
        PricingPlanDataSource: [],
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "cp.geofencing." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("PATCH").h(headers).b(body);
    return b.build();
};
export const se_UpdateKeyCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/metadata/v0/keys/{KeyName}");
    b.p("KeyName", () => input.KeyName, "{KeyName}", false);
    let body;
    body = JSON.stringify(take(input, {
        Description: [],
        ExpireTime: (_) => __serializeDateTime(_),
        ForceUpdate: [],
        NoExpiry: [],
        Restrictions: (_) => _json(_),
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "cp.metadata." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("PATCH").h(headers).b(body);
    return b.build();
};
export const se_UpdateMapCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/maps/v0/maps/{MapName}");
    b.p("MapName", () => input.MapName, "{MapName}", false);
    let body;
    body = JSON.stringify(take(input, {
        ConfigurationUpdate: (_) => _json(_),
        Description: [],
        PricingPlan: [],
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "cp.maps." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("PATCH").h(headers).b(body);
    return b.build();
};
export const se_UpdatePlaceIndexCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/places/v0/indexes/{IndexName}");
    b.p("IndexName", () => input.IndexName, "{IndexName}", false);
    let body;
    body = JSON.stringify(take(input, {
        DataSourceConfiguration: (_) => _json(_),
        Description: [],
        PricingPlan: [],
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "cp.places." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("PATCH").h(headers).b(body);
    return b.build();
};
export const se_UpdateRouteCalculatorCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/routes/v0/calculators/{CalculatorName}");
    b.p("CalculatorName", () => input.CalculatorName, "{CalculatorName}", false);
    let body;
    body = JSON.stringify(take(input, {
        Description: [],
        PricingPlan: [],
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "cp.routes." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("PATCH").h(headers).b(body);
    return b.build();
};
export const se_UpdateTrackerCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/tracking/v0/trackers/{TrackerName}");
    b.p("TrackerName", () => input.TrackerName, "{TrackerName}", false);
    let body;
    body = JSON.stringify(take(input, {
        Description: [],
        EventBridgeEnabled: [],
        KmsKeyEnableGeospatialQueries: [],
        PositionFiltering: [],
        PricingPlan: [],
        PricingPlanDataSource: [],
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "cp.tracking." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("PATCH").h(headers).b(body);
    return b.build();
};
export const se_VerifyDevicePositionCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/tracking/v0/trackers/{TrackerName}/positions/verify");
    b.p("TrackerName", () => input.TrackerName, "{TrackerName}", false);
    let body;
    body = JSON.stringify(take(input, {
        DeviceState: (_) => se_DeviceState(_, context),
        DistanceUnit: [],
    }));
    let { hostname: resolvedHostname } = await context.endpoint();
    if (context.disableHostPrefix !== true) {
        resolvedHostname = "tracking." + resolvedHostname;
        if (!__isValidHostname(resolvedHostname)) {
            throw new Error("ValidationError: prefixed hostname must be hostname compatible.");
        }
    }
    b.hn(resolvedHostname);
    b.m("POST").h(headers).b(body);
    return b.build();
};
export const de_AssociateTrackerConsumerCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
export const de_BatchDeleteDevicePositionHistoryCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Errors: _json,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_BatchDeleteGeofenceCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Errors: _json,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_BatchEvaluateGeofencesCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Errors: (_) => de_BatchEvaluateGeofencesErrorList(_, context),
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_BatchGetDevicePositionCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        DevicePositions: (_) => de_DevicePositionList(_, context),
        Errors: _json,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_BatchPutGeofenceCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Errors: _json,
        Successes: (_) => de_BatchPutGeofenceSuccessList(_, context),
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_BatchUpdateDevicePositionCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Errors: (_) => de_BatchUpdateDevicePositionErrorList(_, context),
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_CalculateRouteCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Legs: (_) => de_LegList(_, context),
        Summary: (_) => de_CalculateRouteSummary(_, context),
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_CalculateRouteMatrixCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        RouteMatrix: (_) => de_RouteMatrix(_, context),
        SnappedDeparturePositions: (_) => de_PositionList(_, context),
        SnappedDestinationPositions: (_) => de_PositionList(_, context),
        Summary: _json,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_CreateGeofenceCollectionCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        CollectionArn: __expectString,
        CollectionName: __expectString,
        CreateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_CreateKeyCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        CreateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
        Key: __expectString,
        KeyArn: __expectString,
        KeyName: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_CreateMapCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        CreateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
        MapArn: __expectString,
        MapName: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_CreatePlaceIndexCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        CreateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
        IndexArn: __expectString,
        IndexName: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_CreateRouteCalculatorCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        CalculatorArn: __expectString,
        CalculatorName: __expectString,
        CreateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_CreateTrackerCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        CreateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
        TrackerArn: __expectString,
        TrackerName: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_DeleteGeofenceCollectionCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
export const de_DeleteKeyCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
export const de_DeleteMapCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
export const de_DeletePlaceIndexCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
export const de_DeleteRouteCalculatorCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
export const de_DeleteTrackerCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
export const de_DescribeGeofenceCollectionCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        CollectionArn: __expectString,
        CollectionName: __expectString,
        CreateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
        Description: __expectString,
        GeofenceCount: __expectInt32,
        KmsKeyId: __expectString,
        PricingPlan: __expectString,
        PricingPlanDataSource: __expectString,
        Tags: _json,
        UpdateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_DescribeKeyCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        CreateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
        Description: __expectString,
        ExpireTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
        Key: __expectString,
        KeyArn: __expectString,
        KeyName: __expectString,
        Restrictions: _json,
        Tags: _json,
        UpdateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_DescribeMapCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Configuration: _json,
        CreateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
        DataSource: __expectString,
        Description: __expectString,
        MapArn: __expectString,
        MapName: __expectString,
        PricingPlan: __expectString,
        Tags: _json,
        UpdateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_DescribePlaceIndexCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        CreateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
        DataSource: __expectString,
        DataSourceConfiguration: _json,
        Description: __expectString,
        IndexArn: __expectString,
        IndexName: __expectString,
        PricingPlan: __expectString,
        Tags: _json,
        UpdateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_DescribeRouteCalculatorCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        CalculatorArn: __expectString,
        CalculatorName: __expectString,
        CreateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
        DataSource: __expectString,
        Description: __expectString,
        PricingPlan: __expectString,
        Tags: _json,
        UpdateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_DescribeTrackerCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        CreateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
        Description: __expectString,
        EventBridgeEnabled: __expectBoolean,
        KmsKeyEnableGeospatialQueries: __expectBoolean,
        KmsKeyId: __expectString,
        PositionFiltering: __expectString,
        PricingPlan: __expectString,
        PricingPlanDataSource: __expectString,
        Tags: _json,
        TrackerArn: __expectString,
        TrackerName: __expectString,
        UpdateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_DisassociateTrackerConsumerCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
export const de_ForecastGeofenceEventsCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        DistanceUnit: __expectString,
        ForecastedEvents: (_) => de_ForecastedEventsList(_, context),
        NextToken: __expectString,
        SpeedUnit: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_GetDevicePositionCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Accuracy: (_) => de_PositionalAccuracy(_, context),
        DeviceId: __expectString,
        Position: (_) => de_Position(_, context),
        PositionProperties: _json,
        ReceivedTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
        SampleTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_GetDevicePositionHistoryCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        DevicePositions: (_) => de_DevicePositionList(_, context),
        NextToken: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_GetGeofenceCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        CreateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
        GeofenceId: __expectString,
        GeofenceProperties: _json,
        Geometry: (_) => de_GeofenceGeometry(_, context),
        Status: __expectString,
        UpdateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_GetMapGlyphsCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
        [_CT]: [, output.headers[_ct]],
        [_CC]: [, output.headers[_cc]],
    });
    const data = await collectBody(output.body, context);
    contents.Blob = data;
    return contents;
};
export const de_GetMapSpritesCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
        [_CT]: [, output.headers[_ct]],
        [_CC]: [, output.headers[_cc]],
    });
    const data = await collectBody(output.body, context);
    contents.Blob = data;
    return contents;
};
export const de_GetMapStyleDescriptorCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
        [_CT]: [, output.headers[_ct]],
        [_CC]: [, output.headers[_cc]],
    });
    const data = await collectBody(output.body, context);
    contents.Blob = data;
    return contents;
};
export const de_GetMapTileCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
        [_CT]: [, output.headers[_ct]],
        [_CC]: [, output.headers[_cc]],
    });
    const data = await collectBody(output.body, context);
    contents.Blob = data;
    return contents;
};
export const de_GetPlaceCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Place: (_) => de_Place(_, context),
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_ListDevicePositionsCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Entries: (_) => de_ListDevicePositionsResponseEntryList(_, context),
        NextToken: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_ListGeofenceCollectionsCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Entries: (_) => de_ListGeofenceCollectionsResponseEntryList(_, context),
        NextToken: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_ListGeofencesCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Entries: (_) => de_ListGeofenceResponseEntryList(_, context),
        NextToken: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_ListKeysCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Entries: (_) => de_ListKeysResponseEntryList(_, context),
        NextToken: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_ListMapsCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Entries: (_) => de_ListMapsResponseEntryList(_, context),
        NextToken: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_ListPlaceIndexesCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Entries: (_) => de_ListPlaceIndexesResponseEntryList(_, context),
        NextToken: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_ListRouteCalculatorsCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Entries: (_) => de_ListRouteCalculatorsResponseEntryList(_, context),
        NextToken: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_ListTagsForResourceCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Tags: _json,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_ListTrackerConsumersCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ConsumerArns: _json,
        NextToken: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_ListTrackersCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Entries: (_) => de_ListTrackersResponseEntryList(_, context),
        NextToken: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_PutGeofenceCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        CreateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
        GeofenceId: __expectString,
        UpdateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_SearchPlaceIndexForPositionCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Results: (_) => de_SearchForPositionResultList(_, context),
        Summary: (_) => de_SearchPlaceIndexForPositionSummary(_, context),
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_SearchPlaceIndexForSuggestionsCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Results: _json,
        Summary: (_) => de_SearchPlaceIndexForSuggestionsSummary(_, context),
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_SearchPlaceIndexForTextCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Results: (_) => de_SearchForTextResultList(_, context),
        Summary: (_) => de_SearchPlaceIndexForTextSummary(_, context),
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_TagResourceCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
export const de_UntagResourceCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
export const de_UpdateGeofenceCollectionCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        CollectionArn: __expectString,
        CollectionName: __expectString,
        UpdateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_UpdateKeyCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        KeyArn: __expectString,
        KeyName: __expectString,
        UpdateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_UpdateMapCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        MapArn: __expectString,
        MapName: __expectString,
        UpdateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_UpdatePlaceIndexCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        IndexArn: __expectString,
        IndexName: __expectString,
        UpdateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_UpdateRouteCalculatorCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        CalculatorArn: __expectString,
        CalculatorName: __expectString,
        UpdateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_UpdateTrackerCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        TrackerArn: __expectString,
        TrackerName: __expectString,
        UpdateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_VerifyDevicePositionCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        DeviceId: __expectString,
        DistanceUnit: __expectString,
        InferredState: (_) => de_InferredState(_, context),
        ReceivedTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
        SampleTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
    });
    Object.assign(contents, doc);
    return contents;
};
const de_CommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "AccessDeniedException":
        case "com.amazonaws.location#AccessDeniedException":
            throw await de_AccessDeniedExceptionRes(parsedOutput, context);
        case "ConflictException":
        case "com.amazonaws.location#ConflictException":
            throw await de_ConflictExceptionRes(parsedOutput, context);
        case "InternalServerException":
        case "com.amazonaws.location#InternalServerException":
            throw await de_InternalServerExceptionRes(parsedOutput, context);
        case "ResourceNotFoundException":
        case "com.amazonaws.location#ResourceNotFoundException":
            throw await de_ResourceNotFoundExceptionRes(parsedOutput, context);
        case "ServiceQuotaExceededException":
        case "com.amazonaws.location#ServiceQuotaExceededException":
            throw await de_ServiceQuotaExceededExceptionRes(parsedOutput, context);
        case "ThrottlingException":
        case "com.amazonaws.location#ThrottlingException":
            throw await de_ThrottlingExceptionRes(parsedOutput, context);
        case "ValidationException":
        case "com.amazonaws.location#ValidationException":
            throw await de_ValidationExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
const throwDefaultError = withBaseException(__BaseException);
const de_AccessDeniedExceptionRes = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    const doc = take(data, {
        Message: [, __expectString, `message`],
    });
    Object.assign(contents, doc);
    const exception = new AccessDeniedException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const de_ConflictExceptionRes = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    const doc = take(data, {
        Message: [, __expectString, `message`],
    });
    Object.assign(contents, doc);
    const exception = new ConflictException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const de_InternalServerExceptionRes = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    const doc = take(data, {
        Message: [, __expectString, `message`],
    });
    Object.assign(contents, doc);
    const exception = new InternalServerException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const de_ResourceNotFoundExceptionRes = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    const doc = take(data, {
        Message: [, __expectString, `message`],
    });
    Object.assign(contents, doc);
    const exception = new ResourceNotFoundException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const de_ServiceQuotaExceededExceptionRes = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    const doc = take(data, {
        Message: [, __expectString, `message`],
    });
    Object.assign(contents, doc);
    const exception = new ServiceQuotaExceededException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const de_ThrottlingExceptionRes = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    const doc = take(data, {
        Message: [, __expectString, `message`],
    });
    Object.assign(contents, doc);
    const exception = new ThrottlingException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const de_ValidationExceptionRes = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    const doc = take(data, {
        FieldList: [, (_) => de_ValidationExceptionFieldList(_, context), `fieldList`],
        Message: [, __expectString, `message`],
        Reason: [, __expectString, `reason`],
    });
    Object.assign(contents, doc);
    const exception = new ValidationException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const se_BatchPutGeofenceRequestEntry = (input, context) => {
    return take(input, {
        GeofenceId: [],
        GeofenceProperties: _json,
        Geometry: (_) => se_GeofenceGeometry(_, context),
    });
};
const se_BatchPutGeofenceRequestEntryList = (input, context) => {
    return input
        .filter((e) => e != null)
        .map((entry) => {
        return se_BatchPutGeofenceRequestEntry(entry, context);
    });
};
const se_BoundingBox = (input, context) => {
    return input
        .filter((e) => e != null)
        .map((entry) => {
        return __serializeFloat(entry);
    });
};
const se_CalculateRouteTruckModeOptions = (input, context) => {
    return take(input, {
        AvoidFerries: [],
        AvoidTolls: [],
        Dimensions: (_) => se_TruckDimensions(_, context),
        Weight: (_) => se_TruckWeight(_, context),
    });
};
const se_CellSignals = (input, context) => {
    return take(input, {
        LteCellDetails: (_) => se_LteCellDetailsList(_, context),
    });
};
const se_Circle = (input, context) => {
    return take(input, {
        Center: (_) => se_Position(_, context),
        Radius: __serializeFloat,
    });
};
const se_DevicePositionUpdate = (input, context) => {
    return take(input, {
        Accuracy: (_) => se_PositionalAccuracy(_, context),
        DeviceId: [],
        Position: (_) => se_Position(_, context),
        PositionProperties: _json,
        SampleTime: __serializeDateTime,
    });
};
const se_DevicePositionUpdateList = (input, context) => {
    return input
        .filter((e) => e != null)
        .map((entry) => {
        return se_DevicePositionUpdate(entry, context);
    });
};
const se_DeviceState = (input, context) => {
    return take(input, {
        Accuracy: (_) => se_PositionalAccuracy(_, context),
        CellSignals: (_) => se_CellSignals(_, context),
        DeviceId: [],
        Ipv4Address: [],
        Position: (_) => se_Position(_, context),
        SampleTime: __serializeDateTime,
        WiFiAccessPoints: _json,
    });
};
const se_ForecastGeofenceEventsDeviceState = (input, context) => {
    return take(input, {
        Position: (_) => se_Position(_, context),
        Speed: __serializeFloat,
    });
};
const se_GeofenceGeometry = (input, context) => {
    return take(input, {
        Circle: (_) => se_Circle(_, context),
        Geobuf: context.base64Encoder,
        Polygon: (_) => se_LinearRings(_, context),
    });
};
const se_LinearRing = (input, context) => {
    return input
        .filter((e) => e != null)
        .map((entry) => {
        return se_Position(entry, context);
    });
};
const se_LinearRings = (input, context) => {
    return input
        .filter((e) => e != null)
        .map((entry) => {
        return se_LinearRing(entry, context);
    });
};
const se_LteCellDetails = (input, context) => {
    return take(input, {
        CellId: [],
        LocalId: _json,
        Mcc: [],
        Mnc: [],
        NetworkMeasurements: (_) => se_LteNetworkMeasurementsList(_, context),
        NrCapable: [],
        Rsrp: [],
        Rsrq: __serializeFloat,
        Tac: [],
        TimingAdvance: [],
    });
};
const se_LteCellDetailsList = (input, context) => {
    return input
        .filter((e) => e != null)
        .map((entry) => {
        return se_LteCellDetails(entry, context);
    });
};
const se_LteNetworkMeasurements = (input, context) => {
    return take(input, {
        CellId: [],
        Earfcn: [],
        Pci: [],
        Rsrp: [],
        Rsrq: __serializeFloat,
    });
};
const se_LteNetworkMeasurementsList = (input, context) => {
    return input
        .filter((e) => e != null)
        .map((entry) => {
        return se_LteNetworkMeasurements(entry, context);
    });
};
const se_Position = (input, context) => {
    return input
        .filter((e) => e != null)
        .map((entry) => {
        return __serializeFloat(entry);
    });
};
const se_PositionalAccuracy = (input, context) => {
    return take(input, {
        Horizontal: __serializeFloat,
    });
};
const se_PositionList = (input, context) => {
    return input
        .filter((e) => e != null)
        .map((entry) => {
        return se_Position(entry, context);
    });
};
const se_TrackingFilterGeometry = (input, context) => {
    return take(input, {
        Polygon: (_) => se_LinearRings(_, context),
    });
};
const se_TruckDimensions = (input, context) => {
    return take(input, {
        Height: __serializeFloat,
        Length: __serializeFloat,
        Unit: [],
        Width: __serializeFloat,
    });
};
const se_TruckWeight = (input, context) => {
    return take(input, {
        Total: __serializeFloat,
        Unit: [],
    });
};
const se_WaypointPositionList = (input, context) => {
    return input
        .filter((e) => e != null)
        .map((entry) => {
        return se_Position(entry, context);
    });
};
const de_BatchEvaluateGeofencesError = (output, context) => {
    return take(output, {
        DeviceId: __expectString,
        Error: _json,
        SampleTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
    });
};
const de_BatchEvaluateGeofencesErrorList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_BatchEvaluateGeofencesError(entry, context);
    });
    return retVal;
};
const de_BatchPutGeofenceSuccess = (output, context) => {
    return take(output, {
        CreateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
        GeofenceId: __expectString,
        UpdateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
    });
};
const de_BatchPutGeofenceSuccessList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_BatchPutGeofenceSuccess(entry, context);
    });
    return retVal;
};
const de_BatchUpdateDevicePositionError = (output, context) => {
    return take(output, {
        DeviceId: __expectString,
        Error: _json,
        SampleTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
    });
};
const de_BatchUpdateDevicePositionErrorList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_BatchUpdateDevicePositionError(entry, context);
    });
    return retVal;
};
const de_BoundingBox = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return __limitedParseDouble(entry);
    });
    return retVal;
};
const de_CalculateRouteSummary = (output, context) => {
    return take(output, {
        DataSource: __expectString,
        Distance: __limitedParseDouble,
        DistanceUnit: __expectString,
        DurationSeconds: __limitedParseDouble,
        RouteBBox: (_) => de_BoundingBox(_, context),
    });
};
const de_Circle = (output, context) => {
    return take(output, {
        Center: (_) => de_Position(_, context),
        Radius: __limitedParseDouble,
    });
};
const de_DevicePosition = (output, context) => {
    return take(output, {
        Accuracy: (_) => de_PositionalAccuracy(_, context),
        DeviceId: __expectString,
        Position: (_) => de_Position(_, context),
        PositionProperties: _json,
        ReceivedTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
        SampleTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
    });
};
const de_DevicePositionList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_DevicePosition(entry, context);
    });
    return retVal;
};
const de_ForecastedEvent = (output, context) => {
    return take(output, {
        EventId: __expectString,
        EventType: __expectString,
        ForecastedBreachTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
        GeofenceId: __expectString,
        GeofenceProperties: _json,
        IsDeviceInGeofence: __expectBoolean,
        NearestDistance: __limitedParseDouble,
    });
};
const de_ForecastedEventsList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_ForecastedEvent(entry, context);
    });
    return retVal;
};
const de_GeofenceGeometry = (output, context) => {
    return take(output, {
        Circle: (_) => de_Circle(_, context),
        Geobuf: context.base64Decoder,
        Polygon: (_) => de_LinearRings(_, context),
    });
};
const de_InferredState = (output, context) => {
    return take(output, {
        Accuracy: (_) => de_PositionalAccuracy(_, context),
        DeviationDistance: __limitedParseDouble,
        Position: (_) => de_Position(_, context),
        ProxyDetected: __expectBoolean,
    });
};
const de_Leg = (output, context) => {
    return take(output, {
        Distance: __limitedParseDouble,
        DurationSeconds: __limitedParseDouble,
        EndPosition: (_) => de_Position(_, context),
        Geometry: (_) => de_LegGeometry(_, context),
        StartPosition: (_) => de_Position(_, context),
        Steps: (_) => de_StepList(_, context),
    });
};
const de_LegGeometry = (output, context) => {
    return take(output, {
        LineString: (_) => de_LineString(_, context),
    });
};
const de_LegList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_Leg(entry, context);
    });
    return retVal;
};
const de_LinearRing = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_Position(entry, context);
    });
    return retVal;
};
const de_LinearRings = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_LinearRing(entry, context);
    });
    return retVal;
};
const de_LineString = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_Position(entry, context);
    });
    return retVal;
};
const de_ListDevicePositionsResponseEntry = (output, context) => {
    return take(output, {
        Accuracy: (_) => de_PositionalAccuracy(_, context),
        DeviceId: __expectString,
        Position: (_) => de_Position(_, context),
        PositionProperties: _json,
        SampleTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
    });
};
const de_ListDevicePositionsResponseEntryList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_ListDevicePositionsResponseEntry(entry, context);
    });
    return retVal;
};
const de_ListGeofenceCollectionsResponseEntry = (output, context) => {
    return take(output, {
        CollectionName: __expectString,
        CreateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
        Description: __expectString,
        PricingPlan: __expectString,
        PricingPlanDataSource: __expectString,
        UpdateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
    });
};
const de_ListGeofenceCollectionsResponseEntryList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_ListGeofenceCollectionsResponseEntry(entry, context);
    });
    return retVal;
};
const de_ListGeofenceResponseEntry = (output, context) => {
    return take(output, {
        CreateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
        GeofenceId: __expectString,
        GeofenceProperties: _json,
        Geometry: (_) => de_GeofenceGeometry(_, context),
        Status: __expectString,
        UpdateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
    });
};
const de_ListGeofenceResponseEntryList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_ListGeofenceResponseEntry(entry, context);
    });
    return retVal;
};
const de_ListKeysResponseEntry = (output, context) => {
    return take(output, {
        CreateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
        Description: __expectString,
        ExpireTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
        KeyName: __expectString,
        Restrictions: _json,
        UpdateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
    });
};
const de_ListKeysResponseEntryList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_ListKeysResponseEntry(entry, context);
    });
    return retVal;
};
const de_ListMapsResponseEntry = (output, context) => {
    return take(output, {
        CreateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
        DataSource: __expectString,
        Description: __expectString,
        MapName: __expectString,
        PricingPlan: __expectString,
        UpdateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
    });
};
const de_ListMapsResponseEntryList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_ListMapsResponseEntry(entry, context);
    });
    return retVal;
};
const de_ListPlaceIndexesResponseEntry = (output, context) => {
    return take(output, {
        CreateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
        DataSource: __expectString,
        Description: __expectString,
        IndexName: __expectString,
        PricingPlan: __expectString,
        UpdateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
    });
};
const de_ListPlaceIndexesResponseEntryList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_ListPlaceIndexesResponseEntry(entry, context);
    });
    return retVal;
};
const de_ListRouteCalculatorsResponseEntry = (output, context) => {
    return take(output, {
        CalculatorName: __expectString,
        CreateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
        DataSource: __expectString,
        Description: __expectString,
        PricingPlan: __expectString,
        UpdateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
    });
};
const de_ListRouteCalculatorsResponseEntryList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_ListRouteCalculatorsResponseEntry(entry, context);
    });
    return retVal;
};
const de_ListTrackersResponseEntry = (output, context) => {
    return take(output, {
        CreateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
        Description: __expectString,
        PricingPlan: __expectString,
        PricingPlanDataSource: __expectString,
        TrackerName: __expectString,
        UpdateTime: (_) => __expectNonNull(__parseRfc3339DateTimeWithOffset(_)),
    });
};
const de_ListTrackersResponseEntryList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_ListTrackersResponseEntry(entry, context);
    });
    return retVal;
};
const de_Place = (output, context) => {
    return take(output, {
        AddressNumber: __expectString,
        Categories: _json,
        Country: __expectString,
        Geometry: (_) => de_PlaceGeometry(_, context),
        Interpolated: __expectBoolean,
        Label: __expectString,
        Municipality: __expectString,
        Neighborhood: __expectString,
        PostalCode: __expectString,
        Region: __expectString,
        Street: __expectString,
        SubMunicipality: __expectString,
        SubRegion: __expectString,
        SupplementalCategories: _json,
        TimeZone: _json,
        UnitNumber: __expectString,
        UnitType: __expectString,
    });
};
const de_PlaceGeometry = (output, context) => {
    return take(output, {
        Point: (_) => de_Position(_, context),
    });
};
const de_Position = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return __limitedParseDouble(entry);
    });
    return retVal;
};
const de_PositionalAccuracy = (output, context) => {
    return take(output, {
        Horizontal: __limitedParseDouble,
    });
};
const de_PositionList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_Position(entry, context);
    });
    return retVal;
};
const de_RouteMatrix = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_RouteMatrixRow(entry, context);
    });
    return retVal;
};
const de_RouteMatrixEntry = (output, context) => {
    return take(output, {
        Distance: __limitedParseDouble,
        DurationSeconds: __limitedParseDouble,
        Error: _json,
    });
};
const de_RouteMatrixRow = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_RouteMatrixEntry(entry, context);
    });
    return retVal;
};
const de_SearchForPositionResult = (output, context) => {
    return take(output, {
        Distance: __limitedParseDouble,
        Place: (_) => de_Place(_, context),
        PlaceId: __expectString,
    });
};
const de_SearchForPositionResultList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_SearchForPositionResult(entry, context);
    });
    return retVal;
};
const de_SearchForTextResult = (output, context) => {
    return take(output, {
        Distance: __limitedParseDouble,
        Place: (_) => de_Place(_, context),
        PlaceId: __expectString,
        Relevance: __limitedParseDouble,
    });
};
const de_SearchForTextResultList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_SearchForTextResult(entry, context);
    });
    return retVal;
};
const de_SearchPlaceIndexForPositionSummary = (output, context) => {
    return take(output, {
        DataSource: __expectString,
        Language: __expectString,
        MaxResults: __expectInt32,
        Position: (_) => de_Position(_, context),
    });
};
const de_SearchPlaceIndexForSuggestionsSummary = (output, context) => {
    return take(output, {
        BiasPosition: (_) => de_Position(_, context),
        DataSource: __expectString,
        FilterBBox: (_) => de_BoundingBox(_, context),
        FilterCategories: _json,
        FilterCountries: _json,
        Language: __expectString,
        MaxResults: __expectInt32,
        Text: __expectString,
    });
};
const de_SearchPlaceIndexForTextSummary = (output, context) => {
    return take(output, {
        BiasPosition: (_) => de_Position(_, context),
        DataSource: __expectString,
        FilterBBox: (_) => de_BoundingBox(_, context),
        FilterCategories: _json,
        FilterCountries: _json,
        Language: __expectString,
        MaxResults: __expectInt32,
        ResultBBox: (_) => de_BoundingBox(_, context),
        Text: __expectString,
    });
};
const de_Step = (output, context) => {
    return take(output, {
        Distance: __limitedParseDouble,
        DurationSeconds: __limitedParseDouble,
        EndPosition: (_) => de_Position(_, context),
        GeometryOffset: __expectInt32,
        StartPosition: (_) => de_Position(_, context),
    });
};
const de_StepList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_Step(entry, context);
    });
    return retVal;
};
const de_ValidationExceptionField = (output, context) => {
    return take(output, {
        Message: [, __expectString, `message`],
        Name: [, __expectString, `name`],
    });
};
const de_ValidationExceptionFieldList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_ValidationExceptionField(entry, context);
    });
    return retVal;
};
const deserializeMetadata = (output) => ({
    httpStatusCode: output.statusCode,
    requestId: output.headers["x-amzn-requestid"] ?? output.headers["x-amzn-request-id"] ?? output.headers["x-amz-request-id"],
    extendedRequestId: output.headers["x-amz-id-2"],
    cfId: output.headers["x-amz-cf-id"],
});
const collectBodyString = (streamBody, context) => collectBody(streamBody, context).then((body) => context.utf8Encoder(body));
const _CC = "CacheControl";
const _CT = "ContentType";
const _FD = "ForceDelete";
const _K = "Key";
const _L = "Language";
const _TK = "TagKeys";
const _cc = "cache-control";
const _ct = "content-type";
const _fD = "forceDelete";
const _k = "key";
const _l = "language";
const _tK = "tagKeys";
